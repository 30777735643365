@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;400;700&display=swap');
/*
=============== 
Variables
===============
*/
:root {
  --max-width: 52rem;
  --border-color: #e5e7eb;
  --clr-navbar-border: rgba(156, 163, 175, 0.1);
  --font-primary: 'Roboto', sans-serif;
  --transition: all 0.3s linear;
  --bezier: cubic-bezier(0.31, 0.11, 0.12, 0.99);
  --clr-green-1: #83d94a;
  --clr-green-2: #aadd88;
  --clr-grey-1: #282c35;
  --clr-grey-2: #3d4352;
  --clr-grey-3: #9ca3af;
  --clr-grey-4: #c2c7cf;
  --clr-white-1: #ffffff;
  --clr-white-2: #edeef0;
  --clr-black: #000000;
}
/*
=============== 
Global Theme Styles
===============
*/
.dark-theme {
  --clr-bcg: var(--clr-grey-1);
  --clr-bcg-secondary: var(--clr-grey-2);
  --clr-font-primary: var(--clr-white-1);
  --clr-font-secondary: var(--clr-white-1);
  --clr-theme-icon: var(--clr-grey-3);
  --clr-timeline-background: var(--clr-grey-2);
  --clr-primary: var(--clr-green-2);
  --clr-tag-background: rgba(170, 221, 136, 0.2);
  --clr-tag-text: var(--clr-green-1);
  --clr-footer-bcg: var(--clr-grey-2);
}

.light-theme {
  --clr-bcg: var(--clr-white-1);
  --clr-bcg-secondary: var(--clr-white-2);
  --clr-font-primary: var(--clr-grey-1);
  --clr-font-secondary: var(--clr-grey-1);
  --clr-theme-icon: var(--clr-black);
  --clr-timeline-background: rgb(245, 245, 250);
  --clr-primary: var(--clr-green-1);
  /* --clr-tag-background: rgba(156, 163, 175, 0.3); */
  --clr-tag-background: var(--clr-white-1);
  --clr-tag-text: var(--clr-green-1);
  --clr-footer-bcg: var(--clr-grey-3);
}
/*
=============== 
Global Styles
===============
*/
*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: var(--clr-font-secondary);
}

li {
  list-style-type: none;
}
body {
  font-family: var(--font-primary);
  background: var(--clr-bcg);
  color: var(--clr-font-primary);
  transition: var(--transition);
}
html {
  scroll-behavior: smooth;
}
.btn {
  display: inline-block;
  padding: 15px;
  color: var(--clr-font-primary);
  font-family: var(--font-primary);
  font-size: 1rem;
  background-color: transparent;
  border: 2px solid var(--clr-primary);
  border-radius: 5px;
  text-decoration: none;
  transition: all 0.4s;
  cursor: pointer;
}
.btn:hover {
  background-color: var(--clr-primary);
}
.btn:disabled {
  border: 2px solid var(--clr-grey-3);
  color: var(--clr-grey-3);
  cursor: not-allowed;
}
.btn[disabled]:hover {
  background-color: transparent;
}
/*
=============== 
Section Styles
===============
*/

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

.section-center .title {
  text-align: center;
  padding-top: 1rem;
  font-size: 2rem;
  font-weight: 400;
}
.separator {
  width: 6rem;
  height: 2px;
  background-color: var(--clr-primary);
  margin: 5px auto 0 auto;
}
/*
=============== 
Navbar Theme Styles
===============
*/
nav {
  border-bottom: 1px solid var(--clr-navbar-border);
}
.blur-bar {
  background-color: var(--clr-blur-background);
}

/*
=============== 
Theme Selector Styles
===============
*/
.mode-btn {
  border: none;
  padding: 0.5rem;
  border-radius: 0.375rem;
  font-size: 1rem;
  cursor: pointer;
  color: var(--clr-theme-icon);
  background-color: var(--clr-bcg-secondary);
}
.mode-btn svg {
  display: block;
}
